.DocCard {
  display: inline-block;
  position: relative;

  &__header {
    background: $doc-card-header-background;
    overflow: hidden;
    border-radius: 3px;
  }

  &__footer {
    display: flex;
    flex-direction: column;
    margin-top: 6px;
    width: 100%;
  }

  &__title {
    display: inline-flex;
    justify-content: space-between;
  }

  &__unviewed-badge {
    background-color: rgba(212, 19, 13, 1);
    position: absolute;
    top: -3px;
    right: -3px;
    border-radius: 50%;
    width: 10px;
    height: 10px;
  }
}
