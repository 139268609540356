@use "sass:math";

.SearchForm {
  // Creations have spacing 24px which looks wider between sort
  // dropdown and query field, so we slightly smaller value which looks
  // better.
  $element-spacing: 20px;
  $border-width-idle: math.div($activation-border-thickness, 2);
  $border-width-focus: $activation-border-thickness;
  $margin-idle: $activation-border-thickness - $border-width-idle;
  $margin-focus: 0;
  $height-base: (
    $input-font-size-base +
    2 * $input-vertical-padding-base +
    2 * $border-width-focus
  );
  $height: math.div($height-base, $base-font-size) * 1em;
  $dropdown-menu-top-border-width: 1px;

  display: flex;
  height: $height;

  &__sort {
    flex: 0 1 40%; // Flex bases have an arbitrary values which simply
                   // works good if there are three tabs on the left
                   // and query field on the right.
    margin-right: $element-spacing;

    .ui.selection.dropdown {
      padding-right: 29px;
      padding-left: 14px;
      min-width: 10em;

      &.active {
        padding-right: 28px;
        padding-left: 13px;

        .icon {
          right: 13px;
        }
      }
    }
  }

  &__query {
    flex: 1 1 auto;
  }

  // Semantic UI form elements overrides

  .ui.input {
    input[type=search] {
      border: {
        color: $border-color;
        radius: $input-border-radius;
        width: $border-width-idle;
      }
      margin: $margin-idle;

      &:focus {
        border: {
          color: $randstad-blue;
          width: $border-width-focus;
        }
        margin: $margin-focus;
      }
    }

    &.icon {
      > i.icon.Icon {
        fill: $randstad-blue;
        margin: {
          left: math.div($input-icon-margin - $glyph-width, 2);
          right: math.div($input-icon-margin - $glyph-width, 2);
        }
        opacity: 1;
        pointer-events: auto;
        width: $glyph-width;

        > svg {
          margin-top: math.div($glyph-width, -2);
          position: absolute;
          right: 0;
          top: 50%;
        }
      }

      input[type=search]:focus ~ i.icon.Icon {
        fill: $default-text-color;
      }
    }
  }
}
