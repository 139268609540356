.CategoryFilter {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 1em 0;

  &__back {
    display: inline-flex;
    font-size: 16px;
    text-align: left;

    i.icon {
      margin: 0em 0.5rem 0em 0em;
      width: auto;
    }
  }
}
