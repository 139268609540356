.Page {
  background-color: white;
  position: relative;
  outline: $activation-border-thickness solid lighten($page_activation_color, 40%);
  overflow: hidden;

  // No bleed styles (outline width adjusted to zero in component)
  &--is-selectable {
    &:hover {
      outline: $activation-border-thickness dashed $page_activation_color;
    }
  }

  &--active, &--active:hover {
    outline: $activation-border-thickness solid $page_activation_color;
  }

  // Bleed styles
  &__bleed-outline {
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    pointer-events: none;
    outline-color: $page_activation_color;
  }

  &--is-selectable &__bleed-outline {
    outline-style: dashed;
  }

  &--active &__bleed-outline, &--active:hover &__bleed-outline {
    outline-style: solid;
  }

  &--show-bleed {
    overflow: visible;
  }
}
