.UnitsToggle {
  background: none;
  border: none;
  font-family: inherit;
  padding: 0;
  cursor: pointer;
  color: $randstad-blue;

  &:hover {
    color: lighten($randstad-blue, 20%);
  }

  &::before {
    content: '('
  }

  &::after {
    content: ')'
  }
}