.SpeechBubble {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  align-self: flex-start;
  border-radius: .5ex;
  display: inline-block;
  flex: none;
  padding: .54ex .98ex .66ex;

  [contenteditable="true"] {
    cursor: text;
    // Must explicitly set user-select to text to work in Safari.
    -webkit-user-select: text;
    user-select: text;
  }
}