.CreatePageSize {
  &__editable-row {
    border-top-color: $randstad-blue !important;
  }

  &__units-container {
    display: flex;
  }

  &__input {
    border: none;
    width: 100%;
  }

  &__units-toggle {
    display: flex;
    justify-content: space-between;
  }
}