.ui.label {
  &.randstad-black {
    background-color: #0F1941;
  }

  &.randstad-blue {
    background-color: #2175D9;
  }

  &.randstad-off-white {
    background-color: #F7F5F0;
  }

  &.randstad-yellow {
    background-color: #FFB511;
  }

  &.randstad-red {
    background-color: #E74536;
  }

  &.randstad-turquoise {
    background-color: #6BA5B4;
  }

  &.randstad-white {
    background-color: #FFFFFF;
  }

  &.randstad-transparent {
    background-color: transparent;
  }
}
