@import './RichTextEditor';

.RichText {
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  overflow: hidden;

  @extend .RichTextEditor;

  // empty paragraphs are rendered as blank lines
  p:empty:before {
    content: "\00a0";
  }
}
