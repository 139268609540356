.PrivacyPolicy {

  &__big_header {
    font-size: 54px;
    color: $randstad-blue;
    padding: 30px;
    text-align: center;
  }

  &__row {
    display: flex;
    flex-wrap: wrap;
    margin: 30px 0;
  }

  &__paragraph_area {
    margin-left: auto;
    width: 75%;
  }

  &__paragraph_text {
    font-size: 15px;
    min-height: 15px;
    line-height: 1.3em;
  }

  &__side_header {
    font-size: 18px;
    color: $randstad-blue;
    width: 24%;
  }

  &__content_area {
    padding-bottom: 120px;
  }

  &__button_bar {
    position:fixed;
    left:0px;
    bottom:0px;
    height:60px;
    width:100%;
    padding: 10px;
    border: 1px solid $border-color;
    text-align: right;
    background-color: $randstad-white;
  }
}
