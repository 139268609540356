@charset "UTF-8";
:root {
  /* Brand Colors */
  --color-brand-primary: #2175d9;
  --color-brand-secondary: #0f1941;
  --color-brand-tertiary: #f7f5f0;
  --color-brand-quaternary: #6ba5b4;
  --color-brand-quinary: #e74536;
  --color-brand-senary: #ffb511;
  /* Tint Colors */
  --color-brand-primary-tint-80: #4d91e1;
  --color-brand-primary-tint-7: #ecf3fc;
  --color-brand-secondary-tint-80: #3c4464;
  --color-brand-secondary-tint-60: #6c738d;
  --color-brand-tertiary-tint-80: #d7d7d7;
  /* Shade Colors */
  --color-brand-primary-shade-130: #175197;
  --color-brand-secondary-shade-180: #03050d;
  --color-brand-tertiary-shade-110: #dedcd8;
  /* Grays */
  --color-white: #ffffff;
  --color-gray-20: #d7d7d7;
  --color-gray-10: #f7f7f7;
  /* UI Colors */
  --color-ui-negative: #e00f0f;
  --color-ui-negative-5: #fdf3f3;
  --color-ui-positive: #257f56;
  --color-ui-positive-5: #f4f8f6;
  --color-ui-warning: #e9a204;
  --color-ui-warning-10: #fcf5e5;
  /* Alpha Colors */
  --color-brand-primary-alpha-10: rgba(33, 117, 217, 0.1);
  --color-brand-primary-alpha-30: rgba(33, 117, 217, 0.3);
  --color-brand-secondary-alpha-10: rgba(15, 25, 65, 0.1);
  --color-brand-secondary-alpha-30: rgba(15, 25, 65, 0.3);
  --color-brand-secondary-alpha-70: rgba(15, 25, 65, 0.7);
  --color-white-alpha-10: rgba(255, 255, 255, 0.1);
  --color-white-alpha-30: rgba(255, 255, 255, 0.3);
  --color-white-alpha-50: rgba(255, 255, 255, 0.5);
}

.ui.label.randstad-black {
  background-color: #0F1941;
}
.ui.label.randstad-blue {
  background-color: #2175D9;
}
.ui.label.randstad-off-white {
  background-color: #F7F5F0;
}
.ui.label.randstad-yellow {
  background-color: #FFB511;
}
.ui.label.randstad-red {
  background-color: #E74536;
}
.ui.label.randstad-turquoise {
  background-color: #6BA5B4;
}
.ui.label.randstad-white {
  background-color: #FFFFFF;
}
.ui.label.randstad-transparent {
  background-color: transparent;
}

.randstad_button {
  font-size: 18px;
  letter-spacing: 0;
  line-height: 30px;
  display: inline-block;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  background: transparent;
  border-radius: 4px;
  border: 2px solid currentColor;
  color: var(--color-brand-primary);
  cursor: pointer;
  font-family: Graphik, Tahoma, "sans-serif";
  height: 60px;
  line-height: 56px;
  min-width: 120px;
  padding: 0 30px;
  position: relative;
  text-align: center;
  text-decoration: none !important;
  -webkit-transition: background-color 0.2s, border-color 0.2s, color 0.2s;
  transition: background-color 0.2s, border-color 0.2s, color 0.2s;
  vertical-align: middle;
}

.randstad_button:hover, .randstad_button:focus {
  background: #2175d9;
  border-color: #2175d9;
  color: #f7f5f0;
  text-decoration: none;
  -webkit-transition: background-color 0.2s, border-color 0.2s, color 0.2s;
  transition: background-color 0.2s, border-color 0.2s, color 0.2s;
}

.randstad_button:focus {
  outline: 2px solid currentColor;
  outline-offset: -5px;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}

.randstad_button:active {
  background: var(--color-brand-primary-shade-130);
  border-color: var(--color-brand-primary-shade-130);
  color: var(--color-brand-tertiary);
  outline: none;
}

@media (hover: none) {
  .randstad_button:hover {
    background: var(--color-brand-primary-shade-130);
    border-color: var(--color-brand-primary-shade-130);
    color: var(--color-brand-tertiary);
    outline: none;
  }
}
.randstad_button--s {
  font-size: 16px;
  height: 40px;
  line-height: 35px;
  min-width: 50px;
  padding: 0 10px;
}

.randstad_button .icon--inline {
  margin-right: 10px;
  top: 2px;
}

.randstad_button .icon svg {
  fill: var(--color-brand-primary);
}

.randstad_button:hover .icon svg, .randstad_button:focus .icon svg {
  fill: var(--color-brand-tertiary);
}

@media (min-width: 941px) {
  .randstad_button--m {
    height: 60px;
    line-height: 56px;
    min-width: 120px;
  }
  .randstad_button--s {
    height: 40px;
    line-height: 35px;
    min-width: 80px;
  }
  .randstad_button--s .icon--inline {
    margin-right: 5px;
  }
  .randstad_button:not(.randstad_button--full-width) {
    max-width: 300px;
  }
}
.ControlBar {
  align-items: center;
  display: flex;
  margin-bottom: 3em;
}
.ControlBar--two-columns {
  flex-wrap: wrap;
}
.ControlBar--two-columns > :first-child {
  flex: 1 1 auto;
}
.ControlBar--two-columns > :last-child {
  flex: 0 1 auto;
}
.ControlBar--short {
  margin-bottom: 1em;
}
.ControlBar h1.ui.header {
  margin: 0;
}

.CategoryFilter {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 1em 0;
}
.CategoryFilter__back {
  display: inline-flex;
  font-size: 16px;
  text-align: left;
}
.CategoryFilter__back i.icon {
  margin: 0em 0.5rem 0em 0em;
  width: auto;
}

.TemplateFilters {
  display: flex;
  gap: 12px;
  font-size: 1rem;
}
.TemplateFilters__filter .dropdown {
  text-transform: lowercase;
}
.TemplateFilters__filter .dropdown .menu > .item {
  text-transform: lowercase;
}
.TemplateFilters__filter__fixed-dropdown {
  color: #a1a1a1;
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: 4px;
  line-height: 1em;
  min-width: 14em;
  padding: 11px 14px;
  text-transform: lowercase;
}

.FlashMessage {
  margin: 1em 0;
}

/**
 * Button to trigger authentication using third-party identity provider.
 *
 * Each button is expected to follow branding guidelines of particular
 * provider. The distinction between button styles is only in their color and
 * icon.
 *
 * 1.  We nest the button itself to the component.
 * 2.  We spread the button and note horizontally to available space. This
 *     also means they are one under another. Typically, the note is bellow
 *     the button providing contextual information.
 * 3.  The components must be used in conjunction with Semantic UI icon
 *     button. Because of this we have to override some properties to achieve
 *     what we want from this component.
 * 4.  The icon has white background which turns off-white when active.
 * 5.  The icon has a thicker solid border. The border’s color is set
 *     by button modifier (see [10]).
 * 6.  We do not render the right border of the icon wrapper to prevent adding
 *     additional visual padding.
 * 8.  Unlike Semantic UI, we render icon as a background to ::after pseudo
 *     element. Semantic UI uses ::before element and effectively disabled
 *     background use there. The background-image is set by each modifier.
 * 9.  The icon occupies 50% of the available space vertically and sits in the
 *     center.
 * 10. We set button colors in the same way as Semantic UI. Because Semantic
 *     UI is in Less and this mechanism is pretty much hardcoded anyway (due
 *     to the way theming and overrides work), we had to copy it here. Apart
 *     from Semantic UI color overrides, we are specifying border-color (see
 *     [5]).
 * 11. Note is not a significant information, so it uses a tiny font size (as
 *     in Semantic UI). The note is only a reminder under the button to give
 *     reassurance. People should know which login button to use.
 */
.LoginButton { /* [1] */ }
.LoginButton__button.ui.icon.button, .LoginButton__note {
  display: block; /* [2][3] */
  width: 100%; /* [2] */
}
.LoginButton__button.ui.icon.button { /* [1] */ }
.LoginButton__button.ui.icon.button > .icon {
  background-color: #FFFFFF; /* [4] */
  /* [5] */
  border-style: solid;
  border-width: 2px;
  border-right: 0; /* [6] */
  opacity: 1; /* [3] */
}
.LoginButton__button.ui.icon.button:active > .icon {
  background-color: #F7F5F0; /* [4] */
}
.LoginButton__button.ui.icon.button > .icon::after { /* [8] */
  content: "";
  /* [9] */
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 50%; /* [9] */
}
.LoginButton__note {
  font-size: 12px;
  text-align: center;
}

.LoginButton--primary > .LoginButton__button.ui.icon.button {
  /* [10] */
}
.LoginButton--primary > .LoginButton__button.ui.icon.button {
  color: #FFFFFF;
  background-color: #2175D9;
}
.LoginButton--primary > .LoginButton__button.ui.icon.button > .icon {
  border-color: #2175D9;
}
.LoginButton--primary > .LoginButton__button.ui.icon.button:hover {
  color: #FFFFFF;
  background-color: rgb(18.409, 104.0899130435, 206.091);
}
.LoginButton--primary > .LoginButton__button.ui.icon.button:hover > .icon {
  border-color: rgb(18.409, 104.0899130435, 206.091);
}
.LoginButton--primary > .LoginButton__button.ui.icon.button:focus {
  color: #FFFFFF;
  background-color: rgb(6.6944, 96.0864695652, 202.5056);
}
.LoginButton--primary > .LoginButton__button.ui.icon.button:focus > .icon {
  border-color: rgb(6.6944, 96.0864695652, 202.5056);
}
.LoginButton--primary > .LoginButton__button.ui.icon.button:active {
  color: #FFFFFF;
  background-color: rgb(26.268, 93.132, 172.732);
}
.LoginButton--primary > .LoginButton__button.ui.icon.button:active > .icon {
  border-color: rgb(26.268, 93.132, 172.732);
}
.LoginButton--primary > .LoginButton__button.ui.icon.button.active {
  color: #FFFFFF;
  background-color: rgb(12.7965, 103.6018695652, 211.7035);
}
.LoginButton--primary > .LoginButton__button.ui.icon.button.active > .icon {
  border-color: rgb(12.7965, 103.6018695652, 211.7035);
}
.LoginButton--primary > .LoginButton__button.ui.icon.button > .icon::after { /* [8] */
  background-image: url("../../images/padlock.svg");
}
.LoginButton--google > .LoginButton__button.ui.icon.button {
  /* [10] */
}
.LoginButton--google > .LoginButton__button.ui.icon.button {
  color: #FFFFFF;
  background-color: #4285F4;
}
.LoginButton--google > .LoginButton__button.ui.icon.button > .icon {
  border-color: #4285F4;
}
.LoginButton--google > .LoginButton__button.ui.icon.button:hover {
  color: #FFFFFF;
  background-color: rgb(30.6275, 114.6579213483, 253.8725);
}
.LoginButton--google > .LoginButton__button.ui.icon.button:hover > .icon {
  border-color: rgb(30.6275, 114.6579213483, 253.8725);
}
.LoginButton--google > .LoginButton__button.ui.icon.button:focus {
  color: #FFFFFF;
  background-color: rgb(14.2, 104.8382022472, 255);
}
.LoginButton--google > .LoginButton__button.ui.icon.button:focus > .icon {
  border-color: rgb(14.2, 104.8382022472, 255);
}
.LoginButton--google > .LoginButton__button.ui.icon.button:active {
  color: #FFFFFF;
  background-color: rgb(17.805, 101.89, 241.195);
}
.LoginButton--google > .LoginButton__button.ui.icon.button:active > .icon {
  border-color: rgb(17.805, 101.89, 241.195);
}
.LoginButton--google > .LoginButton__button.ui.icon.button.active {
  color: #FFFFFF;
  background-color: rgb(29.5, 114.3792134831, 255);
}
.LoginButton--google > .LoginButton__button.ui.icon.button.active > .icon {
  border-color: rgb(29.5, 114.3792134831, 255);
}
.LoginButton--google > .LoginButton__button.ui.icon.button > .icon::after { /* [8] */
  background-image: url("../../images/google.svg");
}

.Navbar {
  border-bottom: 1px solid #d7d7d7 !important;
  padding: 0 20px;
  margin: 0 30px !important;
  width: calc(100% - 60px) !important;
  min-height: 74px !important;
  flex-wrap: wrap;
}
.Navbar--full-width {
  padding: 0 20px;
  margin: 0 !important;
  width: 100% !important;
}
.Navbar__logo {
  max-height: 21px;
}
.Navbar__item--underlined {
  border-bottom-width: 3px !important;
  border-bottom-color: transparent !important;
  padding-bottom: calc(1.28571em - 3px) !important;
  opacity: 0.6;
}
.Navbar__item--underlined:hover {
  border-bottom-color: #999999 !important;
  opacity: 1;
}
.Navbar__item--underlined:active, .Navbar__item--underlined.active {
  border-bottom-color: black !important;
  opacity: 1;
}
.Navbar__item__text {
  display: none;
}
@media only screen and (min-width: 1650px) {
  .Navbar__item__text {
    display: inline;
  }
}
.Navbar__notification-badge {
  background-color: rgb(212, 19, 13);
  color: #ffffff;
  font-size: 12px;
  position: absolute;
  top: 5px;
  right: -5px;
  padding: 2px 4px;
  border-radius: 3px;
}
.Navbar svg {
  width: 20px;
  height: 20px;
  margin-right: 0.8ex;
}
.Navbar.ui.menu:not(.vertical) .item {
  align-items: flex-end;
}
.Navbar.ui.secondary.pointing.menu .button-item {
  align-self: flex-end;
  padding: 0;
  padding-bottom: calc(1.28571em - 13px);
  margin: 0 0.464285em;
}
.Navbar.ui.secondary.pointing.menu .button-item + .button-item {
  margin-left: 0.928571em;
}
.Navbar .ui.dropdown .menu > .item .dropdown.icon {
  float: initial;
}

.SearchForm {
  display: flex;
  height: 2.8571428571em;
}
.SearchForm__sort {
  flex: 0 1 40%;
  margin-right: 20px;
}
.SearchForm__sort .ui.selection.dropdown {
  padding-right: 29px;
  padding-left: 14px;
  min-width: 10em;
}
.SearchForm__sort .ui.selection.dropdown.active {
  padding-right: 28px;
  padding-left: 13px;
}
.SearchForm__sort .ui.selection.dropdown.active .icon {
  right: 13px;
}
.SearchForm__query {
  flex: 1 1 auto;
}
.SearchForm .ui.input input[type=search] {
  border-color: #d7d7d7;
  border-radius: 3px;
  border-width: 1px;
  margin: 1px;
}
.SearchForm .ui.input input[type=search]:focus {
  border-color: #2175D9;
  border-width: 2px;
  margin: 0;
}
.SearchForm .ui.input.icon > i.icon.Icon {
  fill: #2175D9;
  margin-left: 0.6785714286em;
  margin-right: 0.6785714286em;
  opacity: 1;
  pointer-events: auto;
  width: 1.1em;
}
.SearchForm .ui.input.icon > i.icon.Icon > svg {
  margin-top: -0.55em;
  position: absolute;
  right: 0;
  top: 50%;
}
.SearchForm .ui.input.icon input[type=search]:focus ~ i.icon.Icon {
  fill: rgba(0, 0, 0, 0.87);
}

.TableDropdown__select {
  border: none;
  color: inherit;
  font-family: inherit;
  appearance: none;
  cursor: pointer;
  outline: none;
  width: 100%;
}

.CreatePageSize__editable-row {
  border-top-color: #2175D9 !important;
}
.CreatePageSize__units-container {
  display: flex;
}
.CreatePageSize__input {
  border: none;
  width: 100%;
}
.CreatePageSize__units-toggle {
  display: flex;
  justify-content: space-between;
}

.CreateTemplate {
  margin-bottom: 1em;
}
.CreateTemplate__select {
  cursor: pointer;
}

.CardButtonSelect {
  display: flex;
  flex-wrap: wrap;
  column-gap: 10px;
  row-gap: 10px;
  margin: 0 0 1rem;
  list-style: none;
  padding: 0;
}
.CardButtonSelect__item {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
  border: 1px solid rgba(34, 36, 38, 0.15);
  color: rgba(34, 36, 38, 0.5);
  border-radius: 4px;
  height: 120px;
  width: 120px;
  padding: 12px;
}
.CardButtonSelect__item__subtext {
  border-top: 1px solid rgba(34, 36, 38, 0.15);
  padding-top: 4px;
  display: block;
  font-size: 0.8rem;
  line-height: 1.2rem;
}
.CardButtonSelect__item__subtext--selected, .CardButtonSelect__item__subtext--selected:hover {
  border-color: rgb(34, 36, 38);
}
.CardButtonSelect__item:hover {
  border: 1px solid rgba(34, 36, 38, 0.35);
  color: rgba(34, 36, 38, 0.75);
}
.CardButtonSelect__item--selected, .CardButtonSelect__item--selected:hover {
  border: 1px solid rgb(34, 36, 38);
  color: rgb(34, 36, 38);
}
.CardButtonSelect__item--square {
  height: 140px;
  width: 140px;
}
.CardButtonSelect__item--wide {
  height: 140px;
  width: 180px;
}
.CardButtonSelect__item--tall {
  height: 140px;
  width: 100px;
}
.CardButtonSelect__item--button {
  height: 40px;
  width: 140px;
}

.CardControls {
  flex: none;
  user-select: none;
}
.CardControls .ellipsis.vertical.icon {
  color: #a1a1a1;
  text-align: right;
}

.CardInfo {
  width: 100%;
  overflow: hidden;
}
.CardInfo__row {
  color: #a1a1a1;
  font-size: 12px;
  line-height: 18px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.CardInfo__row--personal {
  color: #E74536;
}

.CardTitle {
  font-size: 16px;
  letter-spacing: normal;
  line-height: 21px;
  margin-bottom: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.DocCard {
  display: inline-block;
  position: relative;
}
.DocCard__header {
  background: #d7d7d7;
  overflow: hidden;
  border-radius: 3px;
}
.DocCard__footer {
  display: flex;
  flex-direction: column;
  margin-top: 6px;
  width: 100%;
}
.DocCard__title {
  display: inline-flex;
  justify-content: space-between;
}
.DocCard__unviewed-badge {
  background-color: rgb(212, 19, 13);
  position: absolute;
  top: -3px;
  right: -3px;
  border-radius: 50%;
  width: 10px;
  height: 10px;
}

.DocCardRow {
  display: flex;
  flex-direction: row;
  margin-bottom: 36px;
  width: 100%;
}

.DocPreview {
  display: inline-block;
  text-align: left;
}

.DocShow__page {
  background-color: white;
  box-shadow: 0px 2px 4px 0px rgba(34, 36, 38, 0.12), 0px 2px 10px 0px rgba(34, 36, 38, 0.15);
  overflow: hidden;
  margin-bottom: 3rem;
}
.DocShow__page-content {
  transform-origin: top left;
}

.LinkWithOverlay {
  color: inherit;
  display: block;
  position: relative;
}
.LinkWithOverlay:hover .LinkWithOverlay__overlay {
  background-color: rgba(0, 0, 0, 0.7);
}
.LinkWithOverlay:hover .LinkWithOverlay__content {
  color: white;
}
.LinkWithOverlay__overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 1;
  transition: 0.3s ease background-color;
  background-color: none;
}
.LinkWithOverlay__content {
  color: transparent;
  display: flex;
  font-size: 16px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: 0.3s ease color;
  text-align: center;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  z-index: 1;
}

.Page {
  background-color: white;
  position: relative;
  outline: 2px solid rgba(255, 204, 204, 0.25);
  overflow: hidden;
}
.Page--is-selectable:hover {
  outline: 2px dashed rgba(255, 0, 0, 0.25);
}
.Page--active, .Page--active:hover {
  outline: 2px solid rgba(255, 0, 0, 0.25);
}
.Page__bleed-outline {
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  pointer-events: none;
  outline-color: rgba(255, 0, 0, 0.25);
}
.Page--is-selectable .Page__bleed-outline {
  outline-style: dashed;
}
.Page--active .Page__bleed-outline, .Page--active:hover .Page__bleed-outline {
  outline-style: solid;
}
.Page--show-bleed {
  overflow: visible;
}

.RegionElement {
  min-width: 10px;
  min-height: 10px;
  outline: none;
}

.RichTextEditor p, .RichText p {
  margin: 0;
  line-height: inherit;
}
.RichTextEditor ul, .RichText ul {
  list-style: none;
  margin-bottom: 0;
  margin-top: 0;
  padding-left: 0;
}
.RichTextEditor ul > li, .RichText ul > li {
  padding-left: 0.6em;
  position: relative;
}
.RichTextEditor ul > li:before, .RichText ul > li:before {
  color: var(--marker-color);
  content: "•";
  display: inline-block;
  margin-left: -0.6em;
  overflow: hidden;
  vertical-align: bottom;
  width: 0.6em;
}
.RichTextEditor a, .RichText a {
  color: var(--anchor-color);
}
.RichTextEditor div[contenteditable=true], .RichText div[contenteditable=true] {
  cursor: text;
  -webkit-user-select: text;
  user-select: text;
}

.RichText {
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  overflow: hidden;
}
.RichText p:empty:before {
  content: " ";
}

.RichTextElement {
  white-space: pre-wrap;
  word-break: break-word;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

.SpeechBubble {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  align-self: flex-start;
  border-radius: 0.5ex;
  display: inline-block;
  flex: none;
  padding: 0.54ex 0.98ex 0.66ex;
}
.SpeechBubble [contenteditable=true] {
  cursor: text;
  -webkit-user-select: text;
  user-select: text;
}

.SpeechBubblesElement {
  display: flex;
  flex-direction: column;
}

.TitleTextElement {
  display: flex;
  flex-direction: column;
}

.TitleTextBubble {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  align-self: flex-start;
  display: inline-block;
  flex: none;
}
.TitleTextBubble [contenteditable=true] {
  cursor: text;
  -webkit-user-select: text;
  user-select: text;
}

.CategoryOverview {
  margin-bottom: 1em;
}
.CategoryOverview__heading {
  align-items: baseline;
  display: flex;
  flex-direction: row;
  font-size: 24px;
  font-weight: normal;
  letter-spacing: normal;
  line-height: 21px;
  margin: 2em 0 1em 0 !important;
  text-transform: lowercase;
}
.CategoryOverview__link-all {
  font-size: 15px;
  font-weight: normal;
  margin-left: 2em;
}

.CreationContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.CreationContainer__creation {
  align-self: center;
}

.DocumentPage {
  background-color: white;
  box-shadow: 0px 2px 4px 0px rgba(34, 36, 38, 0.12), 0px 2px 10px 0px rgba(34, 36, 38, 0.15);
  margin-bottom: 2em !important;
  position: relative;
}

.LoginBox {
  background-color: #FFFFFF;
  border-radius: 8px;
  box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.29);
  max-width: 322px;
  overflow-y: auto;
  padding: 1.25rem;
  margin-bottom: 6vw;
}
.LoginBox > * {
  display: block;
  margin-bottom: 2.5rem;
  margin-top: 2.5rem;
  width: 100%;
}
.LoginBox > :first-child {
  margin-top: 1.25rem;
}
.LoginBox > :last-child {
  margin-bottom: 1.25rem;
}
.LoginBox > p {
  padding-left: 0.625rem;
  padding-right: 0.625rem;
}

.SessionsPage {
  min-height: 100vh;
  padding: 6vw;
  position: relative;
  width: 100vw;
}
.SessionsPage--talent {
  background-color: #F7F5F0;
  background-image: url("../../images/connecting-the-dots.svg");
  background-position: 50%;
  background-size: cover;
}
.SessionsPage--digital {
  background-color: #0F1941;
  background-image: url("../../images/login-bg-digital.svg");
  background-position: bottom right;
  background-size: cover;
  background-repeat: no-repeat;
}
.SessionsPage--enterprise {
  background-color: #0F1941;
  background-image: url("../../images/login-bg-enterprise.svg");
  background-position: right;
  background-size: cover;
  background-repeat: no-repeat;
}
.SessionsPage--master {
  background-color: #2175D9;
  background-image: url("../../images/login-bg-master.svg");
  background-position: right;
  background-size: cover;
  background-repeat: no-repeat;
}
.SessionsPage--operational {
  background-color: #0F1941;
  background-image: url("../../images/login-bg-operational.svg");
  background-position: bottom right;
  background-size: cover;
  background-repeat: no-repeat;
}
.SessionsPage--professional {
  background-color: #0F1941;
  background-image: url("../../images/login-bg-professional.svg");
  background-position: bottom right;
  background-size: cover;
  background-repeat: no-repeat;
}
.SessionsPage--tempo-team {
  background-image: url("../../images/tekengebied.jpg");
  background-position: center bottom;
  background-repeat: no-repeat;
  background-size: contain;
}
.SessionsPage--tempo-team::before, .SessionsPage--tempo-team::after {
  content: "";
  position: absolute;
  bottom: 0;
  top: 0;
  z-index: -1;
  background-position: bottom;
  background-repeat: repeat-x;
  background-size: contain;
}
.SessionsPage--tempo-team::before {
  left: 0;
  right: 50vw;
  background-image: url("../../images/tekengebied-left.jpg");
}
.SessionsPage--tempo-team::after {
  left: 50vw;
  right: 0;
  background-image: url("../../images/tekengebied-right.jpg");
}

.PageContent {
  padding-top: 6em;
  padding-bottom: 2em;
}
@media only screen and (min-width: 1200px) {
  .PageContent {
    width: 90vw !important;
  }
}

.Pagination__container {
  width: 100%;
  text-align: center;
  font-size: 2em;
  padding: 40px;
}
.Pagination__item {
  padding: 0 0.2em;
}
.Pagination__image_arrow {
  height: 1em;
  width: 1em;
  vertical-align: text-bottom;
}

.PrivacyPolicy__big_header {
  font-size: 54px;
  color: #2175D9;
  padding: 30px;
  text-align: center;
}
.PrivacyPolicy__row {
  display: flex;
  flex-wrap: wrap;
  margin: 30px 0;
}
.PrivacyPolicy__paragraph_area {
  margin-left: auto;
  width: 75%;
}
.PrivacyPolicy__paragraph_text {
  font-size: 15px;
  min-height: 15px;
  line-height: 1.3em;
}
.PrivacyPolicy__side_header {
  font-size: 18px;
  color: #2175D9;
  width: 24%;
}
.PrivacyPolicy__content_area {
  padding-bottom: 120px;
}
.PrivacyPolicy__button_bar {
  position: fixed;
  left: 0px;
  bottom: 0px;
  height: 60px;
  width: 100%;
  padding: 10px;
  border: 1px solid #d7d7d7;
  text-align: right;
  background-color: #FFFFFF;
}

.TemplateContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.TemplateContainer__template {
  align-self: center;
}

.UnitsToggle {
  background: none;
  border: none;
  font-family: inherit;
  padding: 0;
  cursor: pointer;
  color: #2175D9;
}
.UnitsToggle:hover {
  color: rgb(117.856, 170.944, 234.144);
}
.UnitsToggle::before {
  content: "(";
}
.UnitsToggle::after {
  content: ")";
}