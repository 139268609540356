.LinkWithOverlay {
  color: inherit;
  display: block;
  position: relative;

  $overlay-fade-time: 0.3s;

  &:hover {
    .LinkWithOverlay__overlay {
      background-color: rgba(0, 0, 0, 0.7);
    }

    .LinkWithOverlay__content {
      color: white
    }
  }

  &__overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 1;
    transition: $overlay-fade-time ease background-color;
    background-color: none;
  }

  &__content {
    color: transparent;
    display: flex;
    font-size: 16px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: $overlay-fade-time ease color;
    text-align: center;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    z-index: 1;
  }
}
