/**
 * Button to trigger authentication using third-party identity provider.
 *
 * Each button is expected to follow branding guidelines of particular
 * provider. The distinction between button styles is only in their color and
 * icon.
 *
 * 1.  We nest the button itself to the component.
 * 2.  We spread the button and note horizontally to available space. This
 *     also means they are one under another. Typically, the note is bellow
 *     the button providing contextual information.
 * 3.  The components must be used in conjunction with Semantic UI icon
 *     button. Because of this we have to override some properties to achieve
 *     what we want from this component.
 * 4.  The icon has white background which turns off-white when active.
 * 5.  The icon has a thicker solid border. The border’s color is set
 *     by button modifier (see [10]).
 * 6.  We do not render the right border of the icon wrapper to prevent adding
 *     additional visual padding.
 * 8.  Unlike Semantic UI, we render icon as a background to ::after pseudo
 *     element. Semantic UI uses ::before element and effectively disabled
 *     background use there. The background-image is set by each modifier.
 * 9.  The icon occupies 50% of the available space vertically and sits in the
 *     center.
 * 10. We set button colors in the same way as Semantic UI. Because Semantic
 *     UI is in Less and this mechanism is pretty much hardcoded anyway (due
 *     to the way theming and overrides work), we had to copy it here. Apart
 *     from Semantic UI color overrides, we are specifying border-color (see
 *     [5]).
 * 11. Note is not a significant information, so it uses a tiny font size (as
 *     in Semantic UI). The note is only a reminder under the button to give
 *     reassurance. People should know which login button to use.
 */
.LoginButton { /* [1] */
  &__button.ui.icon.button, /* [1] */
  &__note {
    display: block; /* [2][3] */
    width: 100%; /* [2] */
  }

  &__button.ui.icon.button { /* [1] */
    & > .icon {
      background-color: $randstad-white; /* [4] */
      border: { /* [5] */
        style: solid;
        width: 2px;
      }
      border-right: 0; /* [6] */
      opacity: 1; /* [3] */
    }

    &:active > .icon {
      background-color: $randstad-off-white; /* [4] */
    }

    & > .icon::after { /* [8] */
      content: "";
      background: { /* [9] */
        position: center;
        repeat: no-repeat;
        size: contain;
      }
      height: 50%; /* [9] */
    }
  }

  &__note {
    font-size: 12px;
    text-align: center;
  }
}

@mixin login-button-colors($background-color, $text-color) { /* [10] */
  & {
    color: $text-color;
    background-color: $background-color;
    & > .icon {
      border-color: $background-color;
    }
  }
  &:hover {
    color: $text-color;
    background-color: saturate(darken($background-color, 5%), 10%);
    & > .icon {
      border-color: saturate(darken($background-color, 5%), 10%);
    }
  }
  &:focus {
    color: $text-color;
    background-color: saturate(darken($background-color, 8%), 20%);
    & > .icon {
      border-color: saturate(darken($background-color, 8%), 20%);
    }
  }
  &:active {
    color: $text-color;
    background-color: darken($background-color, 10%);
    & > .icon {
      border-color: darken($background-color, 10%);
    }
  }
  &.active {
    color: $text-color;
    background-color: saturate(darken($background-color, 5%), 15%);
    & > .icon {
      border-color: saturate(darken($background-color, 5%), 15%);
    }
  }
}

.LoginButton {
  &--primary {
    > .LoginButton__button.ui.icon.button {
      @include login-button-colors($randstad-blue, $randstad-white);

      > .icon::after { /* [8] */
        background-image: url('../../images/padlock.svg');
      }
    }
  }

  &--google {
    > .LoginButton__button.ui.icon.button {
      @include login-button-colors(#4285F4, $randstad-white);

      > .icon::after { /* [8] */
        background-image: url('../../images/google.svg');
      }
    }
  }
}
