@use "sass:math";

.LoginBox {
  $vertical-step: 2.5rem;
  $padding: math.div($vertical-step, 2);
  $box-shadow-blur-radius: 4px;

  background-color: $randstad-white;
  border-radius: 8px;
  box-shadow: 1px 1px $box-shadow-blur-radius 0 rgba(0, 0, 0, 0.29);
  max-width: $session-page-breakpoint - 2 * $box-shadow-blur-radius;
  overflow-y: auto;
  padding: $padding;
  margin-bottom: 6vw;

  > * {
    display: block;
    margin: {
      bottom: $vertical-step;
      top: $vertical-step;
    }
    width: 100%;
  }
  > :first-child {
    margin-top: $vertical-step - $padding;
  }
  > :last-child {
    margin-bottom: $vertical-step - $padding;
  }

  > p {
    padding: {
      left: math.div($vertical-step, 4);
      right: math.div($vertical-step, 4);
    }
  }
}
