@charset "UTF-8";

.RichTextEditor {
  p {
    margin: 0;
    line-height: inherit;
  }

  ul {
    $bullet-width: .6em;

    list-style: none;
    margin-bottom: 0;
    margin-top: 0;
    padding-left: 0;

    > li {
      padding-left: $bullet-width;
      position: relative;

      &:before {
        color: var(--marker-color);
        content: "•";
        display: inline-block;
        margin-left: -$bullet-width;
        overflow: hidden;
        vertical-align: bottom;
        width: $bullet-width;
      }
    }
  }

  a {
    color: var(--anchor-color);
  }

  div {
    &[contenteditable="true"] {
      cursor: text;
      // Must explicitly set user-select to text to work in Safari.
      -webkit-user-select: text;
      user-select: text;
    }
  }
}
