.CategoryOverview {
  margin-bottom: 1em;

  &__heading {
    align-items: baseline;
    display: flex;
    flex-direction: row;
    font-size: 24px;
    font-weight: normal;
    letter-spacing: normal;
    line-height: 21px;
    margin: 2em 0 1em 0 !important;
    text-transform: lowercase;
  }

  &__link-all {
    font-size: 15px;
    font-weight: normal;
    margin-left: 2em;
  }
}
