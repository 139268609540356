// WARN: This file contains definitions copied directly from:
// https://unpkg.com/@randstad-design/orbit-multitheme@7.2.0/dist/assets/theme-randstad/orbit-randstad.css
//
// I have prefixed all definitions with "randstad_" so that they uniquely named,
// but otherwise they should be identical to the definitions from the file.
//
// I haven't been able to import the randstad NPM package as a whole, because it
// conflicts with too many of our existing styles. My hope is that this approach
// gives us a way forward.
//
// Copy any new definitions you need into this file manually. If enough of the
// site is eventually using these, we can possibly switch to the standard NPM
// package.

.randstad_button {
  font-size: 18px;
  letter-spacing: 0;
  line-height: 30px;
  display: inline-block;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  background: transparent;
  border-radius: 4px;
  border: 2px solid currentColor;
  color: var(--color-brand-primary);
  cursor: pointer;
  font-family: Graphik, Tahoma, "sans-serif";
  height: 60px;
  line-height: 56px;
  min-width: 120px;
  padding: 0 30px;
  position: relative;
  text-align: center;
  text-decoration: none !important;
  -webkit-transition: background-color 0.2s, border-color 0.2s, color 0.2s;
  transition: background-color 0.2s, border-color 0.2s, color 0.2s;
  vertical-align: middle;
}
.randstad_button:hover, .randstad_button:focus {
  background: #2175d9;
  border-color: #2175d9;
  color: #f7f5f0;
  text-decoration: none;
  -webkit-transition: background-color 0.2s, border-color 0.2s, color 0.2s;
  transition: background-color 0.2s, border-color 0.2s, color 0.2s;
}
.randstad_button:focus {
  outline: 2px solid currentColor;
  outline-offset: -5px;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}
.randstad_button:active {
  background: var(--color-brand-primary-shade-130);
  border-color: var(--color-brand-primary-shade-130);
  color: var(--color-brand-tertiary);
  outline: none;
}

@media (hover: none) {
  .randstad_button:hover {
    background: var(--color-brand-primary-shade-130);
    border-color: var(--color-brand-primary-shade-130);
    color: var(--color-brand-tertiary);
    outline: none; }
}

.randstad_button--s {
  font-size: 16px;
  height: 40px;
  line-height: 35px;
  min-width: 50px;
  padding: 0 10px;
}

.randstad_button .icon--inline {
  margin-right: 10px;
  top: 2px;
}

.randstad_button .icon svg {
  fill: var(--color-brand-primary);
}

.randstad_button:hover .icon svg, .randstad_button:focus .icon svg {
  fill: var(--color-brand-tertiary);
}

@media (min-width: 941px) {
  .randstad_button--m {
    height: 60px;
    line-height: 56px;
    min-width: 120px;
  }
  .randstad_button--s {
    height: 40px;
    line-height: 35px;
    min-width: 80px;
  }
  .randstad_button--s .icon--inline {
      margin-right: 5px;
  }
  .randstad_button:not(.randstad_button--full-width) {
    max-width: 300px;
  }
}
