@use "sass:math";

$base-font-size: 14px;
$default-text-color: rgba(0, 0, 0, 0.87);
$doc-card-header-background: #d7d7d7;
$de-emphasized-text-color: #a1a1a1;
$element_activation_color: #42bffe;
$error-color: #9f3a38;
$activation-border-thickness: 2px;
$inactive-opacity: 0.6;
$grid-image-separation: 7px;
$page_activation_color: rgba(255,0,0,0.25);
$disabled_opacity: 0.5;
$menu-height: 74px; // matches the @minHeight in menu.variables
$border-color: #d7d7d7;
$unselected-copy-link: #0c6be0;
$selected-copy-link: #00b172;
$glyph-width: 1.1em;

// Form input field
$input-font-size-base: $base-font-size;
$input-line-height-base: 17px;
$input-line-height: math.div($input-line-height-base, $input-font-size-base) * 1em;
$input-line-height-offset: math.div($input-line-height - 1em, 2);
$input-vertical-padding-base: 11px;
$input-vertical-padding: (
  math.div($input-vertical-padding-base, $input-font-size-base) * 1em -
  $input-line-height-offset
);
$input-horizontal-padding-base: 14px;
$input-horizontal-padding: math.div($input-horizontal-padding-base, $input-font-size-base) * 1em;
$input-border-radius: 3px;
$input-icon-width: (2 * $input-vertical-padding + $glyph-width);
$input-icon-distance: 0;
$input-icon-margin: ($input-icon-width + $input-icon-distance);

// Colors
$randstad-navy: #0F1941;
$randstad-blue: #2175D9;
$randstad-off-white: #F7F5F0;
$randstad-yellow: #FFB511;
$randstad-red: #E74536;
$randstad-turquoise: #6BA5B4;
$randstad-white: #FFFFFF;

// Layout
$session-page-padding: 6vw;
$session-page-breakpoint: 330px;

// Constants extracted from
// https://unpkg.com/@randstad-design/orbit-multitheme@7.2.0/dist/assets/theme-randstad/variables.css
:root {
  /* Brand Colors */
  --color-brand-primary: #2175d9;
  --color-brand-secondary: #0f1941;
  --color-brand-tertiary: #f7f5f0;
  --color-brand-quaternary: #6ba5b4;
  --color-brand-quinary: #e74536;
  --color-brand-senary: #ffb511;

  /* Tint Colors */
  --color-brand-primary-tint-80: #4d91e1;
  --color-brand-primary-tint-7: #ecf3fc;
  --color-brand-secondary-tint-80: #3c4464;
  --color-brand-secondary-tint-60: #6c738d;
  --color-brand-tertiary-tint-80: #d7d7d7;

  /* Shade Colors */
  --color-brand-primary-shade-130: #175197;
  --color-brand-secondary-shade-180: #03050d;
  --color-brand-tertiary-shade-110: #dedcd8;

  /* Grays */
  --color-white: #ffffff;
  --color-gray-20: #d7d7d7;
  --color-gray-10: #f7f7f7;

  /* UI Colors */
  --color-ui-negative: #e00f0f;
  --color-ui-negative-5: #fdf3f3;
  --color-ui-positive: #257f56;
  --color-ui-positive-5: #f4f8f6;
  --color-ui-warning: #e9a204;
  --color-ui-warning-10: #fcf5e5;

  /* Alpha Colors */
  --color-brand-primary-alpha-10: rgba(33, 117, 217, 0.1);
  --color-brand-primary-alpha-30: rgba(33, 117, 217, 0.3);
  --color-brand-secondary-alpha-10: rgba(15, 25, 65, 0.1);
  --color-brand-secondary-alpha-30: rgba(15, 25, 65, 0.3);
  --color-brand-secondary-alpha-70: rgba(15, 25, 65, 0.7);
  --color-white-alpha-10: rgba(255, 255, 255, 0.1);
  --color-white-alpha-30: rgba(255, 255, 255, 0.3);
  --color-white-alpha-50: rgba(255, 255, 255, 0.5);
}

