.Navbar {
  $blank-space-size: 30px;
  $padding: 20px;

  border-bottom: 1px solid $border-color !important;
  padding: 0 $padding;
  margin: 0 $blank-space-size !important;
  width: calc(100% - #{2 * $blank-space-size}) !important;
  // change for brand guidelines - overriding semantic-ui
  min-height: 74px !important;
  flex-wrap: wrap;

  &--full-width {
    padding: 0 $padding;
    margin: 0 !important;
    width: 100% !important;
  }

  &__logo {
    max-height: 21px;
  }

  &__item {
    &--underlined {
      border-bottom-width: 3px !important;
      border-bottom-color: transparent !important;
      padding-bottom: calc(1.28571em - 3px) !important;
      opacity: $inactive-opacity;

      &:hover {
        border-bottom-color: lighten(black, 60%) !important;
        opacity: 1;
      }

      &:active,
      &.active {
        border-bottom-color: black !important;
        opacity: 1;
      }
    }

    &__text {
      display: none;

      @media only screen and (min-width: 1650px) {
        display: inline;
      }
    }
  }

  &__notification-badge {
    background-color: rgba(212, 19, 13, 1);
    color: #ffffff;
    font-size: 12px;
    position: absolute;
    top: 5px;
    right: -5px;
    padding: 2px 4px;
    border-radius: 3px;
  }

  svg {
    width: 20px;
    height: 20px;
    margin-right: 0.8ex;
  }

  &.ui.menu:not(.vertical) .item {
    align-items: flex-end;
  }

  &.ui.secondary.pointing.menu {
    .button-item {
      align-self: flex-end;
      padding: 0;
      padding-bottom: calc(1.28571em - 13px);
      margin: 0 0.464285em;
    }

    .button-item + .button-item {
      margin-left: 0.928571em;
    }
  }

  .ui.dropdown .menu > .item .dropdown.icon {
    float: initial;
  }
}
