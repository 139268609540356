.CardButtonSelect {
  display: flex;
  flex-wrap: wrap;
  column-gap: 10px;
  row-gap: 10px;
  margin: 0 0 1rem;
  list-style: none;
  padding: 0;

  &__item {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    cursor: pointer;
    border: 1px solid rgba(34, 36, 38, 0.15);
    color: rgba(34, 36, 38, 0.5);
    border-radius: 4px;
    height: 120px;
    width: 120px;
    padding: 12px;

    &__subtext {
      border-top: 1px solid rgba(34, 36, 38, 0.15);
      padding-top: 4px;
      display: block;
      font-size: 0.8rem;
      line-height: 1.2rem;

      &--selected, &--selected:hover {
        border-color: rgba(34, 36, 38, 1);
      }
    }

    &:hover {
      border: 1px solid rgba(34, 36, 38, 0.35);
      color: rgba(34, 36, 38, 0.75);
    }

    &--selected, &--selected:hover {
      border: 1px solid rgba(34, 36, 38, 1);
      color: rgba(34, 36, 38, 1);
    }

    &--square {
      height: 140px;
      width: 140px;
    }

    &--wide {
      height: 140px;
      width: 180px;
    }

    &--tall {
      height: 140px;
      width: 100px;
    }

    &--button {
      height: 40px;
      width: 140px;
    } 
  }
}