.TitleTextElement {
  display: flex;
  flex-direction: column;
}

.TitleTextBubble {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  align-self: flex-start;
  display: inline-block;
  flex: none;

  [contenteditable='true'] {
    cursor: text;
    // Must explicitly set user-select to text to work in Safari.
    -webkit-user-select: text;
    user-select: text;
  }
}
