.SessionsPage {
  min-height: 100vh;
  padding: $session-page-padding;
  position: relative;
  width: 100vw;

  &--talent {
    background: {
      color: $randstad-off-white;
      image: url('../../images/connecting-the-dots.svg');
      position: 50%;
      size: cover;
    }
  }

  &--digital {
    background: {
      color: $randstad-navy;
      image: url('../../images/login-bg-digital.svg');
      position: bottom right;
      size: cover;
      repeat: no-repeat;
    }
  }

  &--enterprise {
    background: {
      color: $randstad-navy;
      image: url('../../images/login-bg-enterprise.svg');
      position: right;
      size: cover;
      repeat: no-repeat;
    }
  }

  &--master {
    background: {
      color: $randstad-blue;
      image: url('../../images/login-bg-master.svg');
      position: right;
      size: cover;
      repeat: no-repeat;
    }
  }

  &--operational {
    background: {
      color: $randstad-navy;
      image: url('../../images/login-bg-operational.svg');
      position: bottom right;
      size: cover;
      repeat: no-repeat;
    }
  }

  &--professional {
    background: {
      color: $randstad-navy;
      image: url('../../images/login-bg-professional.svg');
      position: bottom right;
      size: cover;
      repeat: no-repeat;
    }
  }

  &--tempo-team {
    background: {
      image: url('../../images/tekengebied.jpg');
      position: center bottom;
      repeat: no-repeat;
      size: contain;
    }

    &::before,
    &::after {
      content: '';

      position: absolute;
      bottom: 0;
      top: 0;
      z-index: -1;

      background: {
        position: bottom;
        repeat: repeat-x;
        size: contain;
      }
    }

    &::before {
      left: 0;
      right: 50vw;

      background: {
        image: url('../../images/tekengebied-left.jpg');
      }
    }

    &::after {
      left: 50vw;
      right: 0;

      background: {
        image: url('../../images/tekengebied-right.jpg');
      }
    }
  }
}
