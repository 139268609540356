.Pagination {
  &__container {
    width: 100%;
    text-align: center;
    font-size: 2em;
    padding: 40px;
  }

  &__item {
    padding: 0 0.2em;
  }

  &__image_arrow {
    height: 1em;
    width: 1em;
    vertical-align: text-bottom;
  }
}
