.CardInfo {
  width: 100%;
  overflow: hidden;

  &__row {
    color: $de-emphasized-text-color;
    font-size: 12px;
    line-height: 18px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    &--personal {
      color: $randstad-red;
    }
  }
}
