.DocShow {
  &__page {
    background-color: white;
    box-shadow: 0px 2px 4px 0px rgba(34, 36, 38, 0.12),
      0px 2px 10px 0px rgba(34, 36, 38, 0.15);
    overflow: hidden;
    margin-bottom: 3rem;
  }

  &__page-content {
    transform-origin: top left;
  }
}
