.TemplateFilters {
  display: flex;
  gap: 12px;
  font-size: 1rem;

  &__filter {
    .dropdown {
      text-transform: lowercase;

      .menu > .item {
        text-transform: lowercase;
      }
    }

    &__fixed-dropdown {
      color: $de-emphasized-text-color;
      border: 1px solid rgba(34, 36, 38, 0.15);
      border-radius: 4px;
      line-height: 1em;
      min-width: 14em;
      padding: 11px 14px;
      text-transform: lowercase;
    }
  }
}
