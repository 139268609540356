.ControlBar {
  align-items: center;
  display: flex;
  margin-bottom: 3em;

  &--two-columns {
    flex-wrap: wrap;

    & > :first-child {
      flex: 1 1 auto;
    }

    & > :last-child {
      flex: 0 1 auto;
    }
  }

  &--short {
    margin-bottom: 1em;
  }

  h1.ui.header {
    margin: 0;
  }
}
